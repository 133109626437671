export const VALID_GUESSES = [
  "abaja",
  "abami",
  "abana",
  "adamu",
  "agaca",
  "agaco",
  "agasi",
  "agati",
  "ahari",
  "akabi",
  "akaga",
  "akara",
  "akazi",
  "akazu",
  "akoko",
  "amafi",
  "amafu",
  "amagi",
  "amara",
  "amase",
  "amaso",
  "amata",
  "amato",
  "amavi",
  "amazi",
  "amazu",
  "ameza",
  "amoko",
  "apeti",
  "araza",
  "ariko",
  "ariya",
  "ashwi",
  "aside",
  "asima",
  "avoka",
  "aziya",
  "azote",
  "bambe",
  "bande",
  "barya",
  "birya",
  "bombe",
  "bombo",
  "bucya",
  "bucye",
  "burya",
  "bwana",
  "bwite",
  "byeri",
  "byose",
  "cyami",
  "cyane",
  "cyera",
  "dendo",
  "dushe",
  "ejuru",
  "ekara",
  "elayo",
  "erega",
  "etaje",
  "frigo",
  "gucya",
  "gukwa",
  "gupfa",
  "hamwe",
  "hanze",
  "harya",
  "hefpo",
  "henga",
  "hepfo",
  "hirya",
  "hoshi",
  "ibaba",
  "ibabi",
  "ibara",
  "ibase",
  "ibati",
  "ibaze",
  "ibere",
  "ibiba",
  "ibibi",
  "ibice",
  "ibicu",
  "ibihe",
  "ibiro",
  "ibisa",
  "ibise",
  "ibiti",
  "ibura",
  "ibuye",
  "icumi",
  "icumu",
  "icupa",
  "idini",
  "idoma",
  "iduka",
  "ifoto",
  "ifuku",
  "ifuni",
  "ifuro",
  "ifuru",
  "ifuti",
  "igare",
  "igeri",
  "igice",
  "igico",
  "igicu",
  "igihe",
  "igihu",
  "igiti",
  "ihene",
  "ihoho",
  "ihoni",
  "ihoza",
  "ihuku",
  "ihute",
  "ijage",
  "ijana",
  "ijeri",
  "ijigo",
  "ijipo",
  "ijoro",
  "ijuru",
  "ikara",
  "ikawa",
  "ikaze",
  "ikeki",
  "ikiba",
  "ikilo",
  "ikipe",
  "ikivi",
  "ikoro",
  "ikosa",
  "ikoti",
  "ikuta",
  "imana",
  "imari",
  "imbwa",
  "imena",
  "imeza",
  "imico",
  "imigi",
  "imiti",
  "imizi",
  "imoko",
  "imoso",
  "inabi",
  "inama",
  "inazi",
  "indyo",
  "inema",
  "ineza",
  "ingwa",
  "ingwe",
  "inigi",
  "inkwi",
  "innyo",
  "inoni",
  "inota",
  "inote",
  "inoti",
  "inswa",
  "inuma",
  "ipaje",
  "ipanu",
  "ipasi",
  "ipata",
  "ipera",
  "ipeti",
  "ipiki",
  "ipine",
  "ipusi",
  "irake",
  "irari",
  "irebe",
  "iriba",
  "iriya",
  "iroza",
  "irugu",
  "iruka",
  "isaha",
  "isake",
  "isano",
  "isari",
  "isaro",
  "isasa",
  "isaso",
  "isata",
  "isayo",
  "isazi",
  "isega",
  "isena",
  "iseri",
  "iseru",
  "ishya",
  "isibo",
  "isiha",
  "isima",
  "isine",
  "isoko",
  "isomo",
  "isoni",
  "isosi",
  "isugi",
  "isuka",
  "isuku",
  "isume",
  "isumo",
  "isupu",
  "isura",
  "isure",
  "isuri",
  "isusa",
  "itabi",
  "itaka",
  "itama",
  "itapi",
  "itara",
  "iteka",
  "iteke",
  "itema",
  "iteme",
  "itike",
  "itiro",
  "itoto",
  "ituro",
  "ituze",
  "ivubi",
  "ivuka",
  "ivuko",
  "ivutu",
  "iwabo",
  "iwacu",
  "iwawe",
  "iyatu",
  "iyogi",
  "izamu",
  "iziko",
  "izina",
  "izuba",
  "izuka",
  "izuru",
  "jyewe",
  "kandi",
  "karya",
  "kasho",
  "kenya",
  "kimwe",
  "kirya",
  "kongo",
  "konka",
  "konsa",
  "konti",
  "kubwa",
  "kugwa",
  "kumva",
  "kumwe",
  "kurya",
  "kwiba",
  "kwiga",
  "kwira",
  "kwota",
  "limwe",
  "manda",
  "mbazi",
  "mbega",
  "mbere",
  "mbese",
  "mbiri",
  "mpole",
  "munsi",
  "mwene",
  "mwese",
  "namwe",
  "natwe",
  "ngaho",
  "ngapi",
  "ngoma",
  "nibyo",
  "nkaho",
  "nkana",
  "ntera",
  "nubwo",
  "nyina",
  "nyiri",
  "nyoko",
  "nyuma",
  "nzaba",
  "nzeri",
  "posho",
  "rimwe",
  "rirya",
  "runda",
  "ruswa",
  "rwose",
  "ryari",
  "shehe",
  "sheri",
  "sibyo",
  "sinya",
  "siria",
  "turya",
  "twese",
  "twiga",
  "ubugi",
  "ubuki",
  "ubura",
  "uburo",
  "ubusa",
  "ubuso",
  "ubute",
  "ubuto",
  "ukuri",
  "umubu",
  "umufa",
  "umuja",
  "umuse",
  "umuti",
  "umuze",
  "umuzi",
  "uraho",
  "uriya",
  "urugi",
  "urugo",
  "uruhu",
  "urume",
  "urura",
  "ururo",
  "uruvu",
  "uruzi",
  "uwera",
  "wenda"
]
